<template>
  <div style="display: contents;">
    <XDataTable
        ref="table"
        title="Schedule Tests"
        :items-request="cockpitTimeSchedulingService.getScheduledTestsTable"
        :crud-requests="crudRequests"
        :headers="headers"
        :row-actions="rowActions"
        :item="item"
        history
        search
        @update:item="(newItem) => updateItem(newItem)"
    >
      <template #title-button-slot="{ loadData, historyItem, closeHistory }">
        <XBtn
          v-if="!historyItem"
          icon="mdi-plus"
          text="New"
          color="primary"
          @click="() => openTestDialog({ testItem: createDefaultItem(), loadData })"
        />

        <XBtn v-else icon="mdi-step-backward" text="Back" color="primary" @click="closeHistory"/>
      </template>

      <template #before-search>
        <XSelect label="Resolution" v-model="resolution" :items="resolutions" @input="storeResolution"/>
      </template>
      <template #[`item.nextTime`]="{value,row}">
        <template  v-if="row.active && Date.now() > value">
          {{unixToDateTimeString(value)}}
        </template>
      </template>
      <template #[`item.testCase`]="{value}">
        <AddField
            path="serve.php?f=testing&f2=configureAndRunTests"
            :additionalAttributes="{
              updateTable: false,
              function: 'getInsertedFieldsEdit',
              n_id: value.id,
              n_id_key: shaKey(value.id)
            }"
            :dialogAttributes="{}"
            v-on="$listeners"
        >
          {{ value.name }}
        </AddField>
      </template>

      <template #[`item.testingHistory`]="{row, rowIndex, itemsParams, count}">
        <TestingHistoryChart
          v-if="row"
          :row="row"
          :resolution="resolution"
          :confine="determineWhetherTooltipIsConfine({ rowIndex, itemsPerPage: itemsParams()['items-per-page'], count})"
        />
      </template>

      <template #dialog="{value, item, close, save, viewingHistory}">
        <ScheduleTestsDialog
            :value="value"
            :scheduledTest="item"
            :viewing-history="viewingHistory"
            @save="save"
            @input="close"
        />
      </template>
    </XDataTable>

    <YesNoDialog
        v-model="reallyDeactivateDialog"
        title="Confirm deactivation"
        :text="`Do you really want to deactivate the scheduled test '${item.name}'?`"
        width="320"
        @yes="deactivateScheduledTest"
    />
  </div>
</template>

<script>
import { defineComponent, inject } from "vue"
import XDataTable from '@/components/basic/XDataTable.vue';
import cockpitTimeSchedulingService from '@/js/services/CockpitTimeSchedulingService';
import {unixToDateTimeString} from '@/js/general';
import XSelect from '@/components/basic/XSelect.vue';
import AddField from '@/commonComponents/addField.vue';
import {shaKey} from '@/js/helper';
import TestingHistoryChart from '@/components/specific/TestingHistoryChart.vue';
import ScheduleTestsDialog from '@/components/specific/ScheduleTestsDialog.vue';
import YesNoDialog from '@/components/extended/YesNoDialog.vue';
import XBtn from '@/components/basic/XBtn.vue';

export default defineComponent({
  name: "ScheduleTestsView",

  components: {
    YesNoDialog,
    ScheduleTestsDialog,
    TestingHistoryChart,
    AddField,
    XSelect,
    XDataTable,
    XBtn,
  },

  setup() {
    const { openDialog } = inject("DialogsRoot")

    return {
      openDialog,
    }
  },

  data() {
    return {
      cockpitTimeSchedulingService,
      resolution: this.$store.state.scheduledTestsHistoryResolution || 60,
      resolutions: [60, 30, 15],
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Last Time',
          value: 'lastTime',
          formatter: unixToDateTimeString,
          sortable: true,
        },
        {
          text: 'Next Time',
          value: 'nextTime',
          formatter: unixToDateTimeString,
          sortable: true,
        },
        {
          text: 'Test Name',
          value: 'testCase',
          sortable: true,
        },
        {
          text: 'Testing History',
          value: 'testingHistory',
          width: 360,
        },
      ],
      rowActions: [
        {
          icon: (item) => {
            return !item.active ? 'mdi-play' : 'mdi-pause';
          },
          color: (item) => {
            return !item.active ? 'green' : 'red';
          },
          click: (item) => {
            if (!item.active) {
              cockpitTimeSchedulingService.activateScheduledTest(item.id, () => {
                this.$refs.table.loadData();
              });
            } else {
              this.item = item;
              this.reallyDeactivateDialog = true;
            }
          },
        },
      ],
      item: this.createDefaultItem(),
      reallyDeactivateDialog: false,
    };
  },
  computed: {
    crudRequests() {
      return {
        create: {
          request: cockpitTimeSchedulingService.createScheduledTest,
        },
        update: {
          request: cockpitTimeSchedulingService.updateScheduledTest,
        },
        delete: {
          request: cockpitTimeSchedulingService.deleteScheduledTest,
        },
      };
    },
  },
  methods: {
    unixToDateTimeString,
    shaKey,
    createDefaultItem() {
      return {
        id: 0,
        name: '',
        active: true,
        data: {
          daily: [],
          weekly: {},
          startParameters: {
            explorerTagSyntax: '',
            localVariables: [],
            groupVariables: [],
          },
        },
        lastTime: 0,
        nextTime: 0,
        testCase: {
          category: '',
          id: 0,
          name: '',
          description: '',
        },
        testingHistory: null,
        history: {},
        offcloud: false,
      };
    },

    storeResolution(resolution) {
      this.$store.commit('setScheduledTestsHistoryResolution', resolution);
    },

    deactivateScheduledTest() {
      cockpitTimeSchedulingService.deactivateScheduledTest(this.item.id, () => {
        this.$refs.table.loadData();
      });
    },

    determineWhetherTooltipIsConfine({ itemsPerPage, rowIndex, count }) {
      const NOT_CONFINE_FOR_LAST_N_ROWS = 2;
      const isSmallAmountOfRows = count <= (itemsPerPage - NOT_CONFINE_FOR_LAST_N_ROWS);
      const isNotLastRows = itemsPerPage - (rowIndex + 1) > NOT_CONFINE_FOR_LAST_N_ROWS;

      if (isSmallAmountOfRows || isNotLastRows) {
        return true
      }

      return false
    },

    async openTestDialog({ testItem, loadData  }) {
      const {wasSaved} = await this.openDialog("ScheduleTestsDialog", {
        value: true,
        scheduledTest: testItem,
        viewingHistory: false,
      });
      if (wasSaved) {
        loadData()
      }
    },

    updateItem(item) {
      this.item = item;
    },
  },
});
</script>

