<template>
  <div class="explorers-view">
    <XDataTable
        :disabled="disable"
        ref="table"
        title="Explorers"
        :headers="TABLE_HEADERS"
        :items-request="getExplorersTable"
        :items-request-params="itemsRequestParams"
        :crud-requests="crudRequests"
        :additional-row-actions="additionalRowActions"
        :select-actions="selectActions"
        dialog-width="1800"
        unpadded-dialog
        :row-style="getRowStyle"
        :item="item"
        :csvHeaders="csvHeaders"
        search
        :search-text="searchText"
        @search="updateSearchText"
        @update:item="item = $event"
        :history="$store.state.role <= 2"
        :refresh="30"
        :custom-range="getMaxRange()"
    >
      <template #after-new>
        <XBtn :disabled="disable" v-if="$store.state.role <= 2" text="Import CSV" color="primary" @click="explorerImportDialog = true"/>
      </template>

      <template #before-title>
        <XBtn :disabled="disable" text="Get Explorer" icon="mdi-cloud-download-outline" color="primary" @click="getExplorerDialog = true"/>
      </template>

      <template #before-search>
        <XSelect
            label="Status Filter"
            v-model="statusFilter"
            :items="computedStatusList"
            required
            :autocomplete="true"
        />

        <TagQueryTextField v-model="tagsFilter" class="explorers-view__tag-query-text-field" id="explorers_tag_query"/>

        <XSelect
            label="Device Pool"
            v-model="devicePoolFilter"
            :items="devicePools"
            item-value="id"
            item-text="name"/>
      </template>

      <template #after-search>
        <XBtn text="Clear Filter" icon="mdi-filter-remove" color="primary" @click="clearFilter"/>
      </template>

      <template #above-table>
        <div class="explorers-view__status-bar">
          <XBtn
            class="explorers-view__total-button"
            :text="statusTotal.text"
            height="25"
            :color="onlineFilter !== -1 ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
            elevation="0"
            @click="() => selectTotalStatuses()"
          />

          <SplitButton
            class="explorers-view__statuses-split-btn"
            v-model="onlineFilter"
            :values="statusesCounts"
            :splits="statusesRepr"
            :total="statusesCounts?.total || 0"
          />
        </div>
      </template>

      <template #[`item.name`]="{value, row, rowIndex, handleNameClick}">
        <div class="explorers-view__explorer-name">
          <ExplorerHwBrand :degust-image-version="row.systemInfo.systemType.degustImageVersion" size="24"/>

          <template v-if="!$store.state.organization.config.useExplorerAlias || !row['alias']">
            <ClickableText :text="value" @click="handleNameClick(row, rowIndex)"/>
            <div v-if="row['alias']">({{ row['alias'] }})</div>
          </template>

          <template v-else>
            <ClickableText :text="row['alias']" @click="handleNameClick(row, rowIndex)"/>
            <div>({{ value }})</div>
          </template>
        </div>
      </template>

      <template #[`item.status`]="{value}">
        <ExplorerStatus :value="value"/>
      </template>

      <template #[`item.tags`]="{value}">
        <div class="explorers-view__tags">
          <ClickableText v-for="(tag, i) of value" :key="i" :text="tag" @click="tagsFilter = tag"/>
        </div>
      </template>

      <template #dialog="{value, item, close, save}">
        <ExplorerDialog
          :disabled="disable"
          :value="value"
          :explorer="item"
          @input="close"
          @update-explorer="save"
        />
      </template>

      <template #pagination-slot="{ metadata }">
        <PaginationBlock
          :total-count="metadata.totalCount || 0"
          :current-page="page"
          :items-per-page="metadata.perPage || 0"
          @update:current-page="(pageN) => setPageN(pageN)"
        />
      </template>
    </XDataTable>

    <ExplorerImportDialog
      v-model="explorerImportDialog"
    />

    <GetExplorerDialog
      v-model="getExplorerDialog"
    />

    <AssignToDevicePoolDialog
        v-model="assignToDevicePoolDialog"
        :explorer-ids="selectedExplorersIds"
        @assigned="$refs.table.loadData()"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
import XDataTable from '@/components/basic/XDataTable';
import ClickableText from '@/components/basic/ClickableText';
import ExplorerHwBrand from '@/components/specific/Explorer/ExplorerHwBrand.vue';
import ExplorerStatus from '@/components/specific/Explorer/ExplorerStatus.vue';
import SplitButton from '@/components/basic/SplitButton';
import XBtn from '@/components/basic/XBtn';
import XSelect from '@/components/basic/XSelect';
import TagQueryTextField from '@/components/extended/TagQueryTextField';
import ExplorerImportDialog from '@/components/specific/Explorer/AdditionalDialogs/ExplorerImportDialog.vue';
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import ExplorerDialog from '@/components/specific/Explorer/Dialog/ExplorerDialog.vue';
import explorerStatusService from '@/js/services/ExplorerStatusService';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';
import { getMaxRange, getIntURLSearchParam, unixToDateTimeString } from '@/js/general';
import AssignToDevicePoolDialog from '@/components/specific/Explorer/AdditionalDialogs/AssignToDevicePoolDialog.vue';
import GetExplorerDialog from '@/components/specific/Explorer/AdditionalDialogs/GetExplorerDialog.vue';
import { useStatusBarSelect } from "@/composition/filtering-components/use-status-bar-select"

const TABLE_HEADERS = [
  {
    text: 'Name',
    value: 'name',
    sortable: true,
  },
  {
    text: 'Service Code',
    value: 'serviceCode',
    sortable: true,
  },
  {
    text: 'MAC address',
    value: 'systemInfo',
    sortable: true,
    formatter: (value) => {
      return value ? value.systemType.networkInterfaces.eth0 : '';
    },
  },
  {
    text: 'Status',
    value: 'status',
    sortable: true,
  },
  {
    text: 'Description',
    value: 'description',
    sortable: true,
  },
  {
    text: 'Tags',
    value: 'tags',
  },
  {
    text: 'Device Pool',
    value: 'devicePool.name',
  },
]

const POSSIBLE_STATUSES = [
  {
    "id": 1, //value
    "name": "online", //key
    "text": "Online", //text
    "color": "ok",
    "order": 0
  },
  {
    "id": 2,
    "name": "warning",
    "text": "Warning",
    "color": "testStatusWarning",
    "order": 1
  },
  {
    "id": 0,
    "name": "offline",
    "text": "Offline",
    "color": "testStatusError",
    "order": 2
  }
]

const STATUS_LIST = [
  {
    text: 'All',
    value: -1,
  },
  {
    text: 'In Operation',
    value: 4,
  },
  {
    text: 'Operation Disrupted',
    value: 9,
  },
  {
    text: 'Installed',
    value: 1,
  },
  {
    text: 'In Stock',
    value: 2,
  },
  {
    text: 'Ready to Ship',
    value: 17,
  },
  {
    text: 'Shipping',
    value: 3,
  },
  {
    text: 'Delivered',
    value: 18,
  },
  {
    text: 'Activation Delayed',
    value: 10,
  },
  {
    text: 'Observation',
    value: 7,
  },
  {
    text: 'Observation Disrupted',
    value: 8,
  },
  {
    text: 'Not Connected',
    value: 11,
  },
  {
    text: 'None',
    value: 0,
  },
  {
    text: 'Warning',
    value: 5,
  },
  {
    text: 'Error',
    value: 6,
  },
  {
    text: 'Return',
    value: 12,
  },
  {
    text: 'Wait for Return',
    value: 20,
  },
  {
    text: 'Return Shipping',
    value: 19,
  },
  {
    text: 'Return Received',
    value: 13,
  },
  {
    text: 'Return Delayed',
    value: 14,
  },
  {
    text: 'Inspection Delayed',
    value: 22,
  },
  {
    text: 'Disposed',
    value: 15,
  },
  {
    text: 'Delete',
    value: 16,
  },
  {
    text: 'Pool',
    value: 21,
  }
];


// Termporary solution
// TODO: remove this function after using new table components
function getExplorersTable(
  statusFilter,
  tagsFilter,
  devicePoolFilter,
  onlineFilter,
  page,
  params,
  onSuccess,
  onError
) {
  params["page"] = page
  cockpitExplorerService.getExplorersTable(statusFilter, tagsFilter, devicePoolFilter, onlineFilter, params, onSuccess, onError)
}

export default defineComponent({
  name: 'ExplorersView',

  components: {
    SplitButton,
    GetExplorerDialog,
    AssignToDevicePoolDialog,
    ExplorerDialog,
    ExplorerImportDialog,
    TagQueryTextField,
    XSelect,
    XBtn,
    ExplorerStatus,
    ExplorerHwBrand,
    ClickableText,
    XDataTable,
    PaginationBlock,
  },

  setup() {
    const {
      selectTotalStatuses,
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
    } = useStatusBarSelect({ possibleStatuses: POSSIBLE_STATUSES })

    const page = ref(1);
    const setPageN = (n) => {
      page.value = n;
    }

    return {
      statusesCounts,
      onlineFilter: statusesFilter,
      statusesRepr,
      statusTotal,
      selectTotalStatuses,
      page,
      setPageN,
      getExplorersTable,

      cockpitExplorerService,
      explorerStatusService,
      TABLE_HEADERS,
    }
  },

  data() {
    return {
      dialog: false,
      item: null,
      statusFilter: -1,
      tagsFilter: '',
      devicePoolFilter: 0,
      devicePools: [],
      searchText: '',
      explorerImportDialog: false,
      getExplorerDialog: false,
      businessStatusTypes: [],
      selectActions: [
        {
          text: 'Assign to Device Pool',
          icon: 'mdi-view-grid-plus',
          click: this.assignToDevicePool,
        },
      ],
      selectedExplorersIds: [],
      assignToDevicePoolDialog: false,
    };
  },

  async created() {
    await this.getExplorerCounts();
    cockpitExplorerService.getBusinessStatusTypes((businessStatusTypes) => {
      this.businessStatusTypes = businessStatusTypes;
    });
    cockpitExplorerService.getDevicePools((devicePools) => {
      this.devicePools = devicePools;
    });
    this.devicePoolFilter = getIntURLSearchParam('device-pool-id', 0);
  },

  watch: {
    statusFilter() {
      this.getExplorerCounts();
    },
    tagsFilter() {
      this.getExplorerCounts();
    },
    search() {
      this.getExplorerCounts();
    },
  },

  computed: {
    disable(){ //depends on Role
      let disable = false;
      if(this.$store.state.role ===5){ // 5= Explorer Device Viewer
        disable = true;
      }
      return disable;
    },
    crudRequests() {
      const crudRequests = {
        read: {
          request: cockpitExplorerService.getExplorer,
        },
      };
      if (this.$store.state.role <= 2) {
        crudRequests.update = {
          request: cockpitExplorerService.updateExplorer,
        };
        crudRequests.delete = {
          request: explorerStatusService.deleteExplorer,
        };
      }
      return crudRequests;
    },

    itemsRequestParams() {
      return [this.statusFilter, this.tagsFilter, this.devicePoolFilter, this.onlineFilter, this.page];
    },

    csvHeaders() {
      const csvHeaders = [...TABLE_HEADERS];
      csvHeaders.find(x => x.value === 'status').formatter = (value) => {
        const status = STATUS_LIST.find(x => x.text.replace(' ', '') === value.current);
        if (status) return status.text;
        console.error(`Could not find status ${value.current}`);
        return value.current;
      };
      csvHeaders.splice(2, 0, {
        text: 'Update Time',
        value: 'status',
        formatter: value => unixToDateTimeString(value.updateTime),
      }, {
        text: 'Event Time',
        value: 'status',
        formatter: value => unixToDateTimeString(value.eventTime),
      });
      csvHeaders.find(x => x.value === 'tags').formatter = value => `"${value.join(', ')}"`;
      csvHeaders.push({
        text: 'Operational State',
        value: 'status',
        formatter: value => {
          switch (value.online) {
            case 0:
              return 'Offline';
            case 1:
              return 'Online';
            case 2:
              return 'Warning';
          }
        },
      }, {
        text: 'Operational State Since',
        value: 'status',
        formatter: value => unixToDateTimeString(value.uptimeUpdateTime),
      });
      return csvHeaders;
    },

    additionalRowActions() {
      const additionalRowActions = [
        {
          text: 'Explorer Tests',
          icon: 'mdi-table-eye',
          click: this.openExplorerTests,
        },
      ];
      if (this.$store.state.role <= 2) {
        additionalRowActions.push(
          {
            text: item => !item.active ? 'Activate Explorer' : 'Deactivate Explorer',
            icon: item => !item.active ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off',
            click: (item, i, reload) => {
              explorerStatusService.toggle(item.id, !item.active, () => {
                this.$store.commit(
                  'notification',
                  { text: `Successfully ${!item.active ? 'activated' : 'deactivated'} ${item.name}.` },
                );
                reload();
              });
            },
          },
          {
            text: 'Reboot Explorer',
            icon: 'mdi-restart',
            click: (item, i, reload) => {
              this.$store.commit('notification', { text: `Sent request to reboot ${item.name}. Please wait.` });
              explorerStatusService.reboot(item.name, () => {
                this.$store.commit('notification', { text: `Successfully rebooted ${item.name}.` });
                reload();
              }, () => {
                this.$store.commit('notification', { text: `Could not reboot ${item.name}.` });
              });
            },
          },
        );
      }
      additionalRowActions.push({
        text: 'Assign to Device Pool',
        icon: 'mdi-view-grid-plus',
        click: (explorer) => {
          this.assignToDevicePool([explorer.id]);
        },
      });
      return additionalRowActions;
    },

    computedStatusList() {
      const statusList = [ STATUS_LIST[0] ];
      for (const type of this.businessStatusTypes) {
        const status = STATUS_LIST.find(x => x.value === type);
        if (status) statusList.push(status);
      }
      return statusList;
    },
  },

  methods: {
    getMaxRange,
    getRowStyle(explorer) {
      if (!explorer.active) return 'background-color: var(--v-tableRowDeactivated-base); border-bottom: 1px solid var(--v-tableBorderDeactivated-base);';
      return '';
    },
    clearFilter() {
      this.onlineFilter = -1;
      this.statusFilter = -1;
      this.tagsFilter = '';
      this.devicePoolFilter = 0;
      this.searchText = '';
    },
    openExplorerTests(item) {
      this.safeClick(`?f=testing&f2=explorerTests&name=${encodeURI(item.name)}`);
    },
    async getExplorerCounts() {
      this.statusesCounts = await explorerStatusService.getExplorerCounts(this.statusFilter, this.tagsFilter, this.searchText)
    },
    assignToDevicePool(explorerIds) {
      this.selectedExplorersIds = explorerIds;
      this.assignToDevicePoolDialog = true;
    },
    updateSearchText(value) {
      this.searchText = value;
      this.getExplorerCounts();
    }
  },
});
</script>

<style lang="scss">
.explorers-view {
  height: 100%;

  &__explorer-name {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }

  &__tags {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
  }

  &__tag-query-text-field {
    flex-grow: 1;
    max-width: 350px;
  }

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }
}
</style>
