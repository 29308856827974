<script>
import {defineComponent, ref} from 'vue';
import fiberNet from '@/js/fiber-net';
import SuperAdminMenu from '@/components/specific/menu/SuperAdminMenu.vue';
import requests from '@/js/requests';
import {useStore} from '@/store';
import {tokenRefresher} from '@/auth-tools';
import XSwitch from '@/components/basic/XSwitch.vue';

const ContextSwitcher = () => import(/* webpackChunkName: "context-switcher-dialog" */ "@/components/specific/menu/ContextSwitcher.vue");

export default defineComponent({
  name: 'SystemBar',
  components: {
    XSwitch,
    ContextSwitcher,
    SuperAdminMenu
  },
  computed: {
    fiberNet() {
      return fiberNet;
    },
  },
  setup() {
    const store = useStore();

    const contextSwitcher = ref(false);

    const logOut = () => {
      requests.phpServiceGetRequest('?f=logout', () => {
        tokenRefresher.logout();
      });
    };

    return {
      contextSwitcher,
      logOut,
      store,
    };
  },
});
</script>

<template>
  <div class="system-bar">
    <v-system-bar color="system-bar">
      <template v-if="store.state.user.id">
        <template v-if="store.state.permissions.superAdmin">
          <XSwitch
              v-if="$route.name === 'test-info' || $route.name === 'explorers'"
              title="Admin Info"
              :value="store.state.testInfoAdminInfo"
              @input="store.commit('toggleTestInfoAdminInfo')"
          />

          <XSwitch title="Fiber Net" :value="fiberNet.getEnabled()" @input="fiberNet.setEnabled($event)"/>

          <XSwitch title="Service Log" :value="store.state.logging" @input="store.commit('toggleLogging')"/>

          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small v-bind="attrs" v-on="on" class="super-admin-menu-icon">
                <v-icon color="white">mdi-cog-transfer-outline</v-icon>
              </v-btn>
            </template>

            <v-card>
              <SuperAdminMenu/>
            </v-card>
          </v-menu>

          <div class="white--text">|</div>
        </template>

        <v-btn x-small color="system-bar" elevation="0" class="white--text" @click="contextSwitcher = true">
          Organization: {{ store.state.organization.name }} | Project: {{ store.state.project.name }}
        </v-btn>

        <ContextSwitcher
          v-if="contextSwitcher"
          v-model="contextSwitcher"
        />

        <div class="white--text">|</div>

        <v-btn x-small color="system-bar" elevation="0" class="white--text" @click="logOut">
          Log out {{ store.state.user.firstName }}
        </v-btn>

        <div class="white--text">|</div>

        <a href="https://service.acctopus.com" target="_blank">
          <v-btn x-small color="system-bar" elevation="0" class="white--text">
            Get support
          </v-btn>
        </a>
      </template>
    </v-system-bar>
  </div>
</template>

<style scoped>
.system-bar {
  display: flex;
  justify-content: flex-end;
}

.super-admin-menu-icon {
  margin-right: 6px;
}
</style>
