<script setup>
import {ref, watch} from 'vue';
import requests from '@/js/requests';
import XThrobber from '@/components/basic/XThrobber.vue';
import {useStore} from '@/store';
import {defineProps, defineEmits} from 'vue';
import WrongProjectNotice from '@/components/dynamic/wrongProjectNotice.vue';
import { useNotifications } from '@/composition/notifications';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  public: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['result']);

const store = useStore();
const { showNotification } = useNotifications()

const result = ref(null);
const loading = ref(true);

const isWrongProject = ref(false);

const loadData = () => {
  if (!props.public) {
    requests
        .phpServiceGetRequest(props.url, (response) => {
          if (response.result.content.includes('wrongProjectNotice')) {
            isWrongProject.value = true;
          }

          if (response.result.infoFormMessage) {
            showNotification({message: response.result.infoFormMessage, length: 8000});
          }

          result.value = response.result;

          if (isWrongProject.value) {
            return;
          }

          store.commit('setCurrentPhpServiceViewUrl', props.url);
          emit('result', response.result);
        })
        .finally(() => {
          loading.value = false;
        });
  } else {
    requests
        .publicPhpServiceGetRequest(props.url, (response) => {
          if (response.result.infoFormMessage) {
            showNotification({message: response.result.infoFormMessage, length: 8000});
          }

          result.value = response.result;
          store.commit('setCurrentPhpServiceViewUrl', props.url);
          emit('result', response.result);
          loading.value = false;
        })
        .finally(() => {
          loading.value = false;
        });
  }
};

watch(
    () => props.url,
    () => {
      loadData();
    },
    {immediate: true},
);
</script>

<template>
  <div v-if="result && !loading && !isWrongProject">
    <slot :result="result"/>
  </div>
  <WrongProjectNotice v-else-if="isWrongProject" :result="result"/>
  <XThrobber centered v-else-if="loading"/>
</template>

<style scoped>
</style>
