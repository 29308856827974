<template>
  <div class="mb-2">
    <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        :addable="true"
        addButtTitle="Add User"
        linePrefix="usersAdder">
    </lineFieldsArray>
    <input type="hidden" name="usersAdder" :value="JSON.stringify(pairArray)"/>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import options from "@/cfg/options.json";

export default {
  components: {
    lineFieldsArray,
  },
  // data: function () {

  //   return {
  //     pairArray: this.value,
  //   };
  // },
  props: ["field", "fieldAttributes", "result", "value"],
  computed: {
    pairArray: {
      get() {
        let pairArray = [];
        if (typeof this.value != "undefined" && this.value != "") {
          pairArray = this.value;
        }
        return pairArray;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    fields() {
      let fields = {
        id: {
          type: "combobox",
          name: "User",
          associatedOption: this.result["additional"]["userNames"],
          fieldAttrInput: {class: "required" ,style: "width:600px"},
        },
        n_userType: {
          type: "select",
          name: "Role",
          associatedOption: options.userRoles,
          fieldAttrInput: {class: "required"},
        },
        n_userGroup: {
          type: "select",
          name: "Permission",
          associatedOption: this.result["additional"]["permissionsGroups"],
          fieldAttrInput: {class: "required"},
        },
        remove: {
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      return fields;
    },
  },
  methods: {
    checkInput(value) {
      this.pairArray = [...value];
    },
  },
};
</script>